"use client";
import Select from "@/components/primitives/select";
import Icon from "@/components/primitives/icon";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import Button from "../primitives/button";
import Card from "../primitives/card";
import {
  FeaturedPicks as FeaturedPicksType,
  GameAlignmentType,
  LineType,
  OddVariant,
  PickGroupType,
  PickType,
  RecentPick,
  Sport,
  formatLineType,
  formatOddVariant,
  formatSport,
  getPickAlignment,
  pickToOdd,
  pickTypeToLineType,
  toLineType,
} from "common";
import { ALL_ODD_OPTIONS } from "@/data/sports/options";
import React from "react";
import { formatTime, formatOdd } from "@/utils";
import TeamLogo from "../team/logo";
import Avatar from "../bettor/avatar";
import ReactTimeAgo from "react-time-ago";
import Tooltip from "../primitives/tooltip";
import Link from "next/link";
import { createGameDetailSlug, createProfileSlug } from "@/utils/url";
import { LoadingCircle } from "../primitives/loading";
import { useOddsFormat } from "@/utils/providers/odds-format";

import { useAuth } from "@clerk/nextjs";
import mlClient from "@/ml-client";
import PickPurchaseButton, { PickPurchaseType } from "../pick/purchase-button";
import { PickProvider } from "@/utils/providers/pick";
import NotFound from "../primitives/not-found";

interface FeaturedPicksProps {
  group: PickGroupType;
  sport?: Sport;
  id?: number;
}
export default function FeaturedPicks(props: FeaturedPicksProps) {
  const { oddsFormat } = useOddsFormat();
  const [picks, setPicks] = React.useState<RecentPick[]>([]);
  const [selectedOdds, setSelectedOdds] = React.useState<OddVariant>("all");
  const [loading, setLoading] = React.useState<boolean>(true);
  const { isLoaded, getToken } = useAuth();

  React.useEffect(() => {
    if (!isLoaded) return;
    const getFeaturedPicks = async () => {
      try {
        const token = await getToken();
        const baseUrl = `/picks/${props.group}/featured`;
        const params: URLSearchParams = new URLSearchParams();
        if (selectedOdds !== "all") {
          params.append("line", String(toLineType(selectedOdds as string)));
        }
        if (props.sport !== undefined) {
          params.append("sport", props.sport);
        }
        if (props.id !== undefined) {
          params.append("id", String(props.id));
        }
        const request: string = `${baseUrl}?${params}`;
        setLoading(true);
        const response = await mlClient.get(request, token, {
          validateStatus: () => true,
        });
        if (!response.data.ok) {
          setPicks([]);
        } else {
          setPicks(response.data.data);
        }
      } catch (err) {
        console.error("Error in getFeaturedPicks", err);
      } finally {
        setLoading(false);
      }
    };
    getFeaturedPicks();
  }, [isLoaded, selectedOdds, props.group, props.sport, props.id]);

  console.log("featured picks", picks);

  return (
    <>
      <section className="pt-10 desktop-grid:pt-6">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-6 flex-wrap overflow-y-hidden overflow-x-auto container">
            <div>
              <h1 className="text-headline-lg uppercase flex items-center gap-1">
                <Icon name="diamond" size="lg" className="fill-primary" />
                Featured Picks
                <Tooltip>
                  <span className="text-center max-w-[100px]">
                    Picks made by other bettors of all experience levels. Create
                    picks with a chance to get featured!
                  </span>
                </Tooltip>
              </h1>
            </div>

            <div className="flex items-center gap-4 min-w-[552px] relative">
              {/* {!props.sport && (
                <Select
                  label="Sport"
                  options={ALL_SPORT_OPTIONS}
                  defaultValue={ALL_SPORT_OPTIONS[0].value}
                />
              )} */}

              <Select
                label="Odds"
                options={ALL_ODD_OPTIONS}
                value={selectedOdds}
                onValueChange={(v) => setSelectedOdds(v as OddVariant)}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 mb-6 container">
          <Splide
            hasTrack={false}
            options={{ rewind: true, autoWidth: true, pagination: false }}
          >
            {/* <div className="absolute -right-[220px] w-[320px] h-[240px] top-[16px] z-[3] bg-[linear-gradient(270deg,#F4F4F6_65.15%,rgba(244,244,246,0.00)100%)]"></div> */}
            <SplideTrack className="-mt-6 pt-6 pb-16 -mx-3 desktop-grid:-mx-6 !px-3 desktop-grid:!px-6 !mr-0">
              {loading ? (
                <div className="flex justify-center items-center h-[210px] w-full">
                  <LoadingCircle />
                </div>
              ) : !picks.length ? (
                <div className="flex justify-center items-center h-[210px] w-full">
                  <NotFound label="No picks yet" icon="ticket-buy" />
                </div>
              ) : (
                picks && (
                  <>
                    {picks.map((pick: any, i: number) => {
                      const alignment = getPickAlignment(pick.type);
                      const lineType = pick.type !== null ? pickTypeToLineType(pick.type) : null;
                      const formattedLine = formatLineType(pick.line);
                      const formattedOdd = formatOdd(
                        oddsFormat,
                        pick.odds[alignment]
                      );
                      const formattedAwayOdds = formatOdd(
                        oddsFormat,
                        pick.odds[GameAlignmentType.Away]
                      );
                      const formattedHomeOdds = formatOdd(
                        oddsFormat,
                        pick.odds[GameAlignmentType.Home]
                      );
                      const awayTeam = pick.game.teams[GameAlignmentType.Away];
                      const homeTeam = pick.game.teams[GameAlignmentType.Home];


                      // pick.is_self = false;
                      const showPick: boolean =
                        alignment !== null &&
                        (!pick.sale_price ||
                          (pick.sale_price &&
                            (pick.purchased || pick.is_self)));
                      // const showPick = true;
                      if (i === 0) {
                        console.log("pick is ", pick);
                        console.log("showPick is ", showPick);
                      }
                      return (
                        <SplideSlide key={i}>
                          <Link
                            href={createGameDetailSlug(
                              formatSport(pick.sport_type) as Sport,
                              pick.game.global_game_id
                            )}
                          >
                            <Card
                              custom
                              dark
                              className="p-4 w-[290px] min-h-[205px] h-[242px] gap-4 mr-2"
                            >
                              <div className="flex flex-col items-center justify-center gap-1">
                                <div className="text-light text-headline-medium capitalize">
                                  {formattedLine}
                                </div>
                                <div className="text-label-lg text-secondary">
                                  {formatTime(pick.game.date_time_utc)} -{" "}
                                  {pick.game.platform}
                                </div>
                              </div>
                              <div className="grid grid-cols-[54px_auto_54px] gap-3 relative z-[1] h-[53px]">
                                <div className="flex flex-col justify-center items-center">
                                  <div className="z-[-1] absolute w-[70%] object-fill left-[-30%] top-[-200%] opacity-10 max-w-none pointer-events-none">
                                    <img
                                      src={awayTeam.logoUrl}
                                      alt="Logo Splash"
                                      className="w-full h-full"
                                    />
                                  </div>
                                  <div className="-mt-6 mb-2 z-[1]">
                                    <div className="relative">
                                      <TeamLogo
                                        team={awayTeam}
                                        sport={pick.game.league}
                                      />
                                    </div>
                                  </div>
                                  <div className="text-title-odds text-white">
                                    {awayTeam.abbreviation}
                                  </div>
                                </div>
                                <div
                                  className={`flex flex-col justify-center items-center`}
                                >
                                  {pick.sale_price && (
                                    <div
                                      className={`${
                                        pick.purchased || pick.is_self
                                          ? "absolute -bottom-8"
                                          : ""
                                      }`}
                                    >
                                      <PickProvider pick={pick}>
                                        <PickPurchaseButton
                                          type={PickPurchaseType.Featured}
                                        />
                                      </PickProvider>
                                      {!showPick && (
                                        <div className="flex flex-col gap-y-1 mt-2">
                                          <div className="flex flex-row gap-x-3 justify-between items-center text-label-lg-bold">
                                            <span className="text-surface-700 uppercase min-w-[24px]">
                                              {awayTeam.abbreviation}
                                            </span>
                                            <span className={`text-white`}>
                                              {formattedAwayOdds?.value}
                                            </span>
                                            <span className={`text-secondary`}>
                                              {formattedAwayOdds?.payout}
                                            </span>
                                          </div>
                                          <div className="flex flex-row gap-x-3 justify-between items-center text-label-lg-bold">
                                            <span className="text-surface-700 uppercase min-w-[24px]">
                                              {homeTeam.abbreviation}
                                            </span>
                                            <span className={`text-white`}>
                                              {formattedHomeOdds?.value}
                                            </span>
                                            <span className={`text-secondary`}>
                                              {formattedHomeOdds?.payout}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {showPick && (
                                    <Card
                                      custom
                                      className="!rounded-md gap-1 h-[53px] w-[78px]"
                                    >
                                      {pick.type === PickType.Over ? (
                                        <div className="flex justify-center top-[8px] relative items-center gap-1">
                                          <Icon
                                            name="arrow-up"
                                            className="fill-primary-400"
                                            size="xs"
                                          />
                                          <div className="text-label-lg-bold text-secondary-300 uppercase">
                                            Over
                                          </div>
                                        </div>
                                      ) : pick.type === PickType.Under ? (
                                        <div className="flex justify-center top-[8px] relative items-center gap-1">
                                          <Icon
                                            name="arrow-down"
                                            className="fill-hot"
                                            size="xs"
                                          />
                                          <div className="text-label-lg-bold text-secondary-300 uppercase">
                                            Under
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="flex justify-center items-center gap-2 relative">
                                          <div className="w-6 h-6 absolute flex justify-center items-center left-2 top-1">
                                            <img
                                              src={
                                                alignment === 0
                                                  ? awayTeam.logoUrl
                                                  : homeTeam.logoUrl
                                              }
                                              alt="Logo Splash"
                                              className="w-full h-full"
                                            />
                                          </div>
                                          <div className="flex flex-col absolute top-2 right-3 min-w-[24px]">
                                            <div className="text-title-lg-medium text-dark">
                                              {alignment === 0
                                                ? awayTeam.abbreviation
                                                : homeTeam.abbreviation}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div className="flex items-center gap-1 absolute bottom-2 left-0 right-0 min-w-[56px] justify-center">
                                        <div className="text-title-sm-bold text-dark">
                                          {lineType === LineType.Moneyline
                                            ? formattedOdd?.payout
                                            : formattedOdd?.value}
                                        </div>
                                        <div className="text-label-bold text-secondary-400">
                                          {lineType === LineType.Moneyline
                                            ? undefined
                                            : formattedOdd?.payout}
                                        </div>
                                      </div>
                                    </Card>
                                  )}
                                </div>
                                <div className="flex flex-col justify-center items-center">
                                  <div className="z-[-1] absolute w-[70%] h-[180px] object-fill right-[-30%] top-[-200%] opacity-10 max-w-none pointer-events-none">
                                    <img
                                      src={homeTeam.logoUrl}
                                      alt="Logo Splash"
                                      className="w-full h-full"
                                    />
                                  </div>
                                  <div className="-mt-6 mb-2 z-[1]">
                                    <div className="relative">
                                      <TeamLogo
                                        team={homeTeam}
                                        sport={pick.game.league}
                                      />
                                    </div>
                                  </div>
                                  <div className="text-title-odds text-white">
                                    {homeTeam.abbreviation}
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col items-center mt-4">
                                <div className="text-label-sm-medium uppercase text-secondary">
                                  <Icon
                                    name="clock"
                                    size="xs"
                                    className="fill-secondary"
                                  ></Icon>{" "}
                                  Pick created{" "}
                                  <ReactTimeAgo
                                    date={new Date(pick.created_at)}
                                  ></ReactTimeAgo>
                                </div>
                                <Link
                                  href={createProfileSlug(
                                    pick.bettor.displayName
                                  )}
                                  className="flex flex-col items-center justify-center gap-2"
                                >
                                  <div className="flex flex-row gap-2 items-center mt-1">
                                    <Avatar
                                      size="xs"
                                      image={pick.bettor.avatarUrl}
                                    />
                                    <div className="text-label-lg-medium text-white">
                                      {pick.bettor.displayName}
                                    </div>
                                  </div>
                                  <div className="text-label-lg text-secondary uppercase">
                                    {pick.bettor.record?.wins || 0}-
                                    {pick.bettor.record?.losses || 0}-
                                    {pick.bettor.record?.ties || 0}{" "}
                                    {formatSport(pick.sport_type)} Record |{" "}
                                    {pick.bettor.record?.unitsGained > 0
                                      ? `+${pick.bettor.record?.unitsGained}`
                                      : pick.bettor.record?.unitsGained ||
                                        0}{" "}
                                    Units
                                  </div>
                                </Link>
                              </div>
                            </Card>
                          </Link>
                        </SplideSlide>
                      );
                    })}
                  </>
                )
              )}
            </SplideTrack>
            <div className="splide__arrows absolute flex gap-2 justify-center items-center -top-[5.8rem] min-[422px]:-top-[2.3rem] -right-3 pr-3 z-[4]">
              <Button
                variant="circle"
                color="secondary"
                size="sm"
                className={`splide__arrow splide__arrow--prev`}
              >
                <Icon name="arrow-left" className="fill-dark" size="sm"></Icon>
              </Button>
              <Button
                variant="circle"
                color="secondary"
                size="sm"
                className={`splide__arrow splide__arrow--next`}
              >
                <Icon name="arrow-right" className="fill-dark" size="sm"></Icon>
              </Button>
            </div>
          </Splide>
        </div>
      </section>
      <div className="-mt-12"></div>
    </>
  );
}

function FeaturedPick() {
  return (
    <Card custom dark className="p-4 w-[18.125rem] min-h-[14rem] gap-5 mr-2">
      <div className="flex flex-col items-center justify-center gap-1">
        <div className="text-light text-headline-medium">Spread</div>
        <div className="text-label-lg text-secondary">4:30ET - FOX</div>
      </div>
      <div className="grid grid-cols-3 gap-3 relative z-[1]">
        <div className="flex flex-col justify-center items-center">
          <img
            src="/images/teams/Team=ARI.svg"
            alt=""
            className="z-[0] absolute w-[70%] object-fill left-[-30%] top-[-200%] opacity-10 max-w-none pointer-events-none"
          />
          <img
            src="/images/teams/Team=ARI.svg"
            alt=""
            className="w-full -mt-10 z-[1]"
          />
          <div className="text-title-odds text-white">ARI</div>
        </div>
        <Card custom className="!rounded-md gap-1">
          <div className="flex justify-center items-center gap-2 relative">
            <img
              src="/images/teams/Team=TEN.svg"
              alt=""
              className="w-6 h-6 absolute left-2 top-1"
            />
            <div className="flex flex-col absolute top-2 right-3">
              <div className="text-title-lg-medium text-dark">TEN</div>
            </div>
          </div>
          <div className="flex items-center gap-1 absolute bottom-2 left-3">
            <div className="text-label-bold text-dark">-13.5</div>
            <div className="text-label-sm-bold text-secondary-400">-117</div>
          </div>
        </Card>
        <div className="flex flex-col justify-center items-center">
          <img
            src="/images/teams/Team=TEN.svg"
            alt=""
            className="absolute w-[70%] object-fill right-[-30%] top-[-200%] opacity-10 max-w-none pointer-events-none"
          />
          <img
            src="/images/teams/Team=TEN.svg"
            alt=""
            className="w-full -mt-10 z-[1]"
          />
          <div className="text-title-odds text-white">TEN</div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-2">
        <Icon variant="circle" name="" className="bg-error-300" size="sm" />
        <div className="text-label-lg-medium text-white">GrandSlamAce16</div>
        <div className="text-label-lg text-secondary">+44100 Units</div>
      </div>
    </Card>
  );
}
